// NavBar.js

import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa'; // Importing icons from react-icons
import './NavBar.css';
import Logo from './logo-bs.png';
import { auth } from "../../../../firebase";
import { useNavigate } from "react-router-dom";


const NavBar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      localStorage.clear();
      navigate("/signin");
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };


  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
          <a href="/"><img src={Logo} alt="" style={{"height": "50px"}}/></a>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          {showMenu ? <FaTimes /> : <FaBars />} {/* Using react-icons */}
        </div>
        <ul className={showMenu ? 'navbar-menu active' : 'navbar-menu'}>
          <li onClick={handleSignOut}>Logout</li>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;