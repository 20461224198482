// Score.js

import React from 'react';
import './ProgressCard.css';

const ProgressCard = () => {
    return (
        <div className="score-card">
            <h3 className="score-title">Score</h3>
            <div className="progress-bars">
                <div className="rating-bar-container">
                    <p>CAT0</p>
                    <div className="rating-bar">
                        <div className="rating-fill" style={{ width: `100%` }}></div>
                    </div>
                    <p>CAT1</p>
                    <div className="rating-bar">
                        <div className="rating-fill" style={{ width: `75%` }}></div>
                    </div>
                    <p>CAT2</p>
                    <div className="rating-bar">
                        <div className="rating-fill" style={{ width: `100%` }}></div>
                    </div>
                    <p>CAT</p>
                    <div className="rating-bar">
                        <div className="rating-fill" style={{ width: `25%` }}></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProgressCard;