// CalendarCard.jsx

import React from 'react';
import './CalendarCard.css';

const CalendarCard = ({ sessionData }) => {
  // Filter out sessions whose date has not yet arrived
  const upcomingSessions = sessionData.filter(session => new Date(session.date) > new Date());

  return (
    <div className="calendar-card">
      <h2>Upcoming Sessions</h2>
      <div className="session-list">
        {upcomingSessions.length > 0 ? (
          upcomingSessions.map((session, index) => (
            <div key={index} className="session-item">
              <div className="session-date">{new Date(session.date).toLocaleDateString()} - {session.reminder}</div>
              <div className="session-info">
                <div className="session-name">{session.name}</div>
                <div className="session-details">{session.details}</div>
              </div>
            </div>
          ))
        ) : (
          <div className="no-sessions">No upcoming sessions</div>
        )}
      </div>
    </div>
  );
};

export default CalendarCard;