import React from 'react';

const CourseCard = ({ title, description, imageUrl, price }) => {
  return (
    <div className="bg-white text-black rounded-lg shadow-lg p-6 mb-6 relative flex flex-col items-center">
      <img src={imageUrl} alt={title} className="rounded-lg mb-4" style={{ maxWidth: '100%' }} />
      <h2 className="text-xl font-bold mb-2">{title}</h2>
      <p>{description}</p>
      <p className="text-yellow-600 font-bold mt-2">Price: {price} EGP</p>
    </div>
  );
};

const OurCourses = () => {
  return (
    <div className="bg-white py-12 px-6 text-center">
      <h1 className="text-3xl font-bold mb-6">Our Courses</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        <CourseCard
          title="Course 1"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio."
          imageUrl="https://via.placeholder.com/300"
          price={150} // Example price in EGP
        />
        <CourseCard
          title="Course 2"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio."
          imageUrl="https://via.placeholder.com/300"
          price={200} // Example price in EGP
        />
        <CourseCard
          title="Course 3"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio."
          imageUrl="https://via.placeholder.com/300"
          price={100} // Example price in EGP
        />
      </div>
      <button className="bg-black text-white py-2 px-4 mt-6 rounded-lg mx-auto">Explore More</button>
    </div>
  );
};

export default OurCourses;
