import React from 'react';

const AboutUs = () => {
  return (
    <div className="bg-black text-yellow-600 py-12 px-6 flex flex-col lg:flex-row items-center justify-center">
      <img
        src="https://img.freepik.com/free-photo/close-up-hand-muscular-man-with-bandage_155003-14511.jpg?t=st=1711871994~exp=1711875594~hmac=467ac86ee61257d4e953efd8e97e11b4544965e231acce40de4d8de0537f9d9b&w=826"
        alt="About Us"
        className="rounded-lg shadow-lg mb-6 lg:mb-0 lg:mr-6 max-w-full lg:max-w-[400px] sm:max-w-[300px]"
      />
      <div className="text-white lg:w-1/2">
        <h1 className="text-3xl text-yellow-600 font-bold mb-4">About Us</h1>
        <p className="text-lg">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante
          dapibus diam. Sed nisi.
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nihil minima ut quia sit eveniet corrupti harum, quam natus eum. Aspernatur, voluptatibus? Ullam quod delectus deserunt temporibus odit id perferendis accusamus!
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
