import React from "react";
import './Products.css';

const Products = () => {
    return(
        <div className="flex items-center justify-center min-h-screen container mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="rounded-xl shadow-lg">
                    <div className="p-5 flex flex-col">
                        <div className="rounded-xl overflow-hidden product-image">
                            <img src="https://static.wixstatic.com/media/4df4af_98676b114a504648a71d11bebbeecea1~mv2.png/v1/fill/w_288,h_190,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/4df4af_98676b114a504648a71d11bebbeecea1~mv2.png" alt="Product" />
                        </div>
                        <h5 className="text-2xl md:text-3xl font-medium mt-3">Product Title</h5>
                        <p className="text-slate-500 text-lg mt-3">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolor iste mollitia, dignissimos esse ad nihil facilis saepe. Debitis voluptate perspiciatis ab maxime ea commodi, ut consequatur possimus optio repudiandae quis.</p>
                        <a href="/shop" className="text-center bg-black text-white py-2 rounded-lg font-semibold mt-4 hover:bg-yellow-600 focus:scale-95 transition-all duration-200 ease-out">Buy Now</a>
                    </div>
                </div>
                <div className="rounded-xl shadow-lg">
                    <div className="p-5 flex flex-col">
                        <div className="rounded-xl overflow-hidden product-image">
                            <img src="https://static.wixstatic.com/media/4df4af_98676b114a504648a71d11bebbeecea1~mv2.png/v1/fill/w_288,h_190,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/4df4af_98676b114a504648a71d11bebbeecea1~mv2.png" alt="Product" />
                        </div>
                        <h5 className="text-2xl md:text-3xl font-medium mt-3">Product Title</h5>
                        <p className="text-slate-500 text-lg mt-3">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolor iste mollitia, dignissimos esse ad nihil facilis saepe. Debitis voluptate perspiciatis ab maxime ea commodi, ut consequatur possimus optio repudiandae quis.</p>
                        <a href="/shop" className="text-center bg-black text-white py-2 rounded-lg font-semibold mt-4 hover:bg-yellow-600 focus:scale-95 transition-all duration-200 ease-out">Buy Now</a>
                    </div>
                </div>
                <div className="rounded-xl shadow-lg">
                    <div className="p-5 flex flex-col">
                        <div className="rounded-xl overflow-hidden product-image">
                            <img src="https://static.wixstatic.com/media/4df4af_98676b114a504648a71d11bebbeecea1~mv2.png/v1/fill/w_288,h_190,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/4df4af_98676b114a504648a71d11bebbeecea1~mv2.png" alt="Product" />
                        </div>
                        <h5 className="text-2xl md:text-3xl font-medium mt-3">Product Title</h5>
                        <p className="text-slate-500 text-lg mt-3">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolor iste mollitia, dignissimos esse ad nihil facilis saepe. Debitis voluptate perspiciatis ab maxime ea commodi, ut consequatur possimus optio repudiandae quis.</p>
                        <a href="/shop" className="text-center bg-black text-white py-2 rounded-lg font-semibold mt-4 hover:bg-yellow-600 focus:scale-95 transition-all duration-200 ease-out">Buy Now</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Products;