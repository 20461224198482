// ParentComponent.jsx

import React from 'react';
import CalendarCard from '../CalendarCard/CalendarCard';
import AttendanceCard from '../AttendanceCard/AttendaceCard';
import './SessionComponent.css';
import MembershipStatus from '../MembershipStatus/MembershipStatus';
import MembershipRenew from '../MembershipRenew/MembershipRenew';

const SessionComponent = ({ sessionData }) => {
  const usedSessions = 15; // Number of sessions already used
  const totalSessions = 20;
  return (
    <div className="parent-container">
      <CalendarCard sessionData={sessionData} />
      <AttendanceCard sessionData={sessionData} />
      <MembershipStatus usedSessions={usedSessions} totalSessions={totalSessions} />
      <MembershipRenew />
    </div>
  );
};

export default SessionComponent;