import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Hero from "./components/Body/Hero";
import Products from "./components/Body/Products";
import AboutUs from "./components/Body/AboutUs";
import OurCourses from "./components/Body/ourCourses";
import OurGallery from "./components/Body/OurGallery";
import ContactForm from "./components/Body/contact";
import Footer from "./components/Body/Footer";
import SignUp from "./components/Auth0/SignUp/SignUp";
import SignIn from "./components/Auth0/SignIn/SignIn";
import Dash from "./components/dash/dash";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Hero />
                <Products />
                <AboutUs />
                <OurCourses />
                <OurGallery />
                <ContactForm />
                <Footer />
              </>
            }
          />
          <Route path="/auth" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/dashboard" element={<Dash />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
