// MembershipStatus.js

import React from 'react';
import './MembershipStatus.css';

const MembershipStatus = ({ usedSessions, totalSessions }) => {
    const percentage = (usedSessions / totalSessions) * 100;

    return (
        <div className="membership-card">

            <div className="membership-status">
                <h3>Membership Status</h3>

                <div className="membership-progress" data-progress={percentage}>
                    <div className="membership-progress-line">
                    <div className="rating-fill" style={{ width: `${percentage}%` }}></div>

                    </div>
                </div>
                <div className="membership-info">
                    <p>You used {usedSessions} out of {totalSessions} sessions</p>
                </div>
            </div>
        </div>
    );
};

export default MembershipStatus;