import React from "react";
import Header from "../Header/Header";

const Hero = () => {
  return (
    <>
      <Header />
      <div className="flex flex-col items-center justify-center min-h-screen bg-black text-gold-500">
        <h1 className="text-5xl sm:text-6xl md:text-7xl font-bold mb-4 text-yellow-600 text-center">Solo Art's Academy</h1>
        <p className="text-lg sm:text-xl md:text-2xl text-gray-500 mb-4 text-center">
          Unleash Your Potential, Discover Your Passion
        </p>
        <button className="bg-yellow-600 hover:bg-yellow-500 text-white font-bold py-2 px-4 rounded mt-4">
          Enroll Now
        </button>
      </div>
    </>
  );
};

export default Hero;
