import React, { useState, useEffect } from "react"; // Import useState hook
import NavBar from "./cards/NavBar/NavBar";
import WelcomeCard from "./cards/WelcomeCard/WelcomeCard";
import SessionComponent from "./cards/SessionsComponent/SessionComponent";
import ProgressCard from "./cards/ProgressCard/ProgressCard";
import { useCookies } from "react-cookie";
import { firestore } from "../../firebase";

const Dash = () => {
  const [cookies] = useCookies(["userId"]);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (cookies.userId) {
      // Retrieve user info from firestore
      const fetchUserInfo = async () => {
        try {
          const userInfoRef = firestore
            .collection("usersInfo")
            .doc(cookies.userId);
          const doc = await userInfoRef.get();
          if (doc.exists) {
            // Set user information in state
            setUserInfo(doc.data());
          } else {
            console.log("404, User Info Not Found !!!");
          }
        } catch (error) {
          console.error("Error fetching user info:", error);
        }
      };

      fetchUserInfo();
    }
  }, [cookies.userId]);

  const sessionData = [
    {
      date: new Date("2024-04-01"),
      reminder: "Meeting",
      attendanceStatus: "Attended",
    },
    {
      date: new Date("2024-03-02"),
      reminder: "Presentation",
      attendanceStatus: "Absent",
    },
    // Add more data for other dates
  ];

  return (
    <>
      <NavBar />
      {userInfo && (
        <>
          <WelcomeCard userName={userInfo.fullName} profileImage={userInfo.profileImage} />
          <SessionComponent sessionData={sessionData} />
          <ProgressCard />
        </>
      )}
    </>
  );
};

export default Dash;
