import React, { useState } from "react";
import { auth } from "../../../firebase";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const SignIn = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [, setCookie] = useCookies(["userId"]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await auth.signInWithEmailAndPassword(email, password);
      console.log("Signed In successfully");
  
      // Retrieve user's UID
      const currentUser = userCredential.user;
      const { uid } = currentUser;
  
      // Store user's UID in a cookie
     setCookie("userId", uid, { path: "/" });
  
      navigate("/dashboard");
    } catch (error) {
      console.error("Error signing in:", error);
      let message = "An error occurred. Please try again.";
      if (error.code === "auth/user-not-found") {
        message = "User not found. Please check your email.";
      } else if (error.code === "auth/wrong-password") {
        message = "Incorrect password. Please try again.";
      }
      setErrorMessage(message);
    }
  };
  

  return (
    <div className="bg-gray-900 min-h-screen flex items-center justify-center">
      <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold mb-4 text-gray-900">Sign In</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-gray-700 font-bold mb-2"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              className="px-4 py-2 rounded-lg w-full border-gray-300 focus:border-yellow-600 focus:outline-none"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-gray-700 font-bold mb-2"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              className="px-4 py-2 rounded-lg w-full border-gray-300 focus:border-yellow-600 focus:outline-none"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="w-full bg-yellow-600 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline"
          >
            Sign In
          </button>
          {errorMessage && <p className="text-red-500 mt-4">{errorMessage}</p>}
          <p className="mt-4 text-gray-700">
          Don't have an account?{" "}
          <span
            className="text-blue-600 cursor-pointer"
            onClick={() => navigate("/auth")}
          >
            Create one
          </span>
        </p>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
