import React from 'react';

const OurGallery = () => {
  return (
    <div className="bg-black text-yellow-600 p-8 rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Our Gallery</h2>
      <div className="grid grid-cols-3 gap-4">
        {/* Placeholder images */}
        <img src="https://via.placeholder.com/300x200" alt='Alt' className="w-full h-auto rounded-md" />
        <img src="https://via.placeholder.com/300x200" alt='Alt' className="w-full h-auto rounded-md" />
        <img src="https://via.placeholder.com/300x200" alt='Alt' className="w-full h-auto rounded-md" />
        <img src="https://via.placeholder.com/300x200" alt='Alt' className="w-full h-auto rounded-md" />
        <img src="https://via.placeholder.com/300x200" alt='Alt' className="w-full h-auto rounded-md" />
        <img src="https://via.placeholder.com/300x200" alt='Alt' className="w-full h-auto rounded-md" />

        {/* Add more placeholders as needed */}
      </div>
    </div>
  );
}

export default OurGallery;
