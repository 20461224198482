// Card.js

import React from 'react';
import './WelcomeCard.css';

const WelcomeCard = ({ userName, rating, message, profileImage }) => {

  return (
    <div className="card">
      <div className="left-content">
        <div className="profile">
          {/* Placeholder for the profile picture */}
          <img src={profileImage} alt="Profile" />
        </div>
        <div className="greeting">
          <h2>Hi, {userName}</h2>
          {/*<IoMdHand />*/}
        </div>
      </div>
      <div className="right-content">
        <div className="rating">
        <span>Rating: {rating}%</span>

          <div className="rating-bar-container">
            <div className="rating-bar">
              <div className="rating-fill" style={{ width: `${rating}%` }}></div>
            </div>

          </div>
        </div>
        <div className="message">
          <p>{message || "No message from teacher"}</p>
        </div>
      </div>
    </div>
  );
};

export default WelcomeCard;
