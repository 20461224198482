import React, { useState } from "react";
import { auth, firestore, storage } from "../../../firebase"; // Adjust the path as needed
import { useNavigate } from "react-router-dom"; // Import useNavigate hook

const SignUp = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("Male");
  const [branch, setBranch] = useState("Branch0");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate
  const [profileImage, setProfileImage] = useState(null); // New state for profile image


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }

    try {
      const userCredential = await auth.createUserWithEmailAndPassword(
        email,
        password
      );
      const user = userCredential.user;

      // Upload Profile Image
      const profileImageRef = storage.ref().child(`profileImages/${user.uid}`);
      await profileImageRef.put(profileImage);

      const profileImageUrl = await profileImageRef.getDownloadURL();

      await firestore.collection("usersInfo").doc(user.uid).set({
        fullName: fullName,
        email: email,
        mobileNumber: mobileNumber,
        age: age,
        gender: gender,
        branch: branch,
        profileImage: profileImageUrl, 
        role: "student",
      });

      await user.updateProfile({
        displayName: fullName,
      });

      // Save user Data
      saveUserDataLocally(user);

      navigate("/signin");


      setErrorMessage("");
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const saveUserDataLocally = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
  };


  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setProfileImage(e.target.files[0]);
    }
  };


return (
  <div className="bg-gray-900 min-h-screen flex items-center justify-center">
    <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold mb-4 text-gray-900">Sign Up</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            htmlFor="fullName"
            className="block text-gray-700 font-bold mb-2"
          >
            Full Name
          </label>
          <input
            type="text"
            id="fullName"
            className="px-4 py-2 rounded-lg w-full border-gray-300 focus:border-yellow-600 focus:outline-none"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            placeholder="Full Name"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 font-bold mb-2">
            Email
          </label>
          <input
            type="email"
            id="email"
            className="px-4 py-2 rounded-lg w-full border-gray-300 focus:border-yellow-600 focus:outline-none"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="password"
            className="block text-gray-700 font-bold mb-2"
          >
            Password
          </label>
          <input
            type="password"
            id="password"
            className="px-4 py-2 rounded-lg w-full border-gray-300 focus:border-yellow-600 focus:outline-none"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="confirm_password"
            className="block text-gray-700 font-bold mb-2"
          >
            Confirm Password
          </label>
          <input
            type="password"
            id="confirm_password"
            className="px-4 py-2 rounded-lg w-full border-gray-300 focus:border-yellow-600 focus:outline-none"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm Password"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="mobile_number"
            className="block text-gray-700 font-bold mb-2"
          >
            Mobile Number
          </label>
          <input
            type="tel"
            id="mobile_number"
            className="px-4 py-2 rounded-lg w-full border-gray-300 focus:border-yellow-600 focus:outline-none"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            placeholder="Phone number"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="age" className="block text-gray-700 font-bold mb-2">
            Age
          </label>
          <input
            type="number"
            id="age"
            className="px-4 py-2 rounded-lg w-full border-gray-300 focus:border-yellow-600 focus:outline-none"
            value={age}
            onChange={(e) => setAge(e.target.value)}
            placeholder="Age"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="gender"
            className="block text-gray-700 font-bold mb-2"
          >
            Gender
          </label>
          <select
            id="gender"
            className="px-4 py-2 rounded-lg w-full border-gray-300 focus:border-yellow-600 focus:outline-none"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
          >
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
        <div className="mb-4">
          <label
            htmlFor="branch"
            className="block text-gray-700 font-bold mb-2"
          >
            Branch
          </label>
          <select
            id="branch"
            className="px-4 py-2 rounded-lg w-full border-gray-300 focus:border-yellow-600 focus:outline-none"
            value={branch}
            onChange={(e) => setBranch(e.target.value)}
          >
            <option value="Branch0">Branch0</option>
            <option value="Branch1">Branch1</option>
            <option value="Branch2">Branch2</option>
            <option value="Online">Online</option>
          </select>
        </div>
        <div className="mb-4">
            <label
              htmlFor="profileImage"
              className="block text-gray-700 font-bold mb-2"
            >
              Profile Image
            </label>
            <input
              type="file"
              id="profileImage"
              onChange={handleImageChange}
              className="px-4 py-2 rounded-lg w-full border-gray-300 focus:border-yellow-600 focus:outline-none"
            />
          </div>
        <button
          type="submit"
          className="w-full bg-yellow-600 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline"
        >
          Sign Up
        </button>
      </form>
      {errorMessage && <p className="text-red-500 mt-4">{errorMessage}</p>}
      <p className="mt-4 text-gray-700">
          Already have an account?{" "}
          <span
            className="text-blue-600 cursor-pointer"
            onClick={() => navigate("/signin")}
          >
            Sign In
          </span>
        </p>
    </div>
  </div>
);
};

export default SignUp;
