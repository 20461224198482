// Footer.js

import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-black py-4">
      <div className="">
        <div className="flex justify-center  bg-black items-center">
          <p className="text-yellow-600 mr-2">© 2024 Solo Arts Academy</p>
          <p className="text-gray-400">All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
