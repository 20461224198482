import React from 'react';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa'; // Importing necessary icons from react-icons
import './contact.css';
function ContactForm() {
  return (
    <div className="container">
      <div className="content">
        <div className="left-side">
          <div className="address details">
            <FaMapMarkerAlt className="icon" /> {/* Replaced <i className="fas fa-map-marker-alt"></i> */}
            <div className="topic">Address</div>
            <div className="text-one">Cairo, Cairo</div>
            <div className="text-two">03 ST.</div>
          </div>
          <div className="phone details">
            <FaPhoneAlt className="icon" /> {/* Replaced <i className="fas fa-phone-alt"></i> */}
            <div className="topic">Phone</div>
            <div className="text-one">+201272033078</div>
            <div className="text-two">+201111111111</div>
          </div>
          <div className="email details">
            <FaEnvelope className="icon" /> {/* Replaced <i className="fas fa-envelope"></i> */}
            <div className="topic">Email</div>
            <div className="text-one">Solo@Solo.cairo-code.site</div>
            <div className="text-two">Mail@mail.ltd</div>
          </div>
        </div>
        <div className="right-side">
          <div className="topic-text">Send us a message</div>
          <p>If you have any work from me or any types of queries related to my tutorial, you can send me a message from here. It's my pleasure to help you.</p>
          <form action="#">
            <div className="input-box">
              <input type="text" placeholder="Enter your name" className="w-full py-2 px-4 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-purple-500" />
            </div>
            <div className="input-box">
              <input type="text" placeholder="Enter your email" className="w-full py-2 px-4 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-purple-500" />
            </div>
            <div className="input-box message-box">
              <textarea placeholder="Type your message here" className="w-full py-2 px-4 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-purple-500"></textarea>
            </div>
            <div className="button">
              <input type="button" value="Send Now" className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
