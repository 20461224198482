
const firebaseConfig = {
  apiKey: "AIzaSyAqtGi4FcFzhHnhFR0rbl6GClPHpcYBJTE",
  authDomain: "solo-art-s-academy-production.firebaseapp.com",
  databaseURL: "https://solo-art-s-academy-production-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "solo-art-s-academy-production",
  storageBucket: "solo-art-s-academy-production.appspot.com",
  messagingSenderId: "611010159737",
  appId: "1:611010159737:web:499d3928e51bb80e1720a8"
};

export { firebaseConfig };