import React from 'react';
import './AttendanceCard.css';

const AttendanceCard = ({ sessionData }) => {
  // Filter sessions that have already passed
  const passedSessions = sessionData.filter(session => session.date < new Date());

  return (
    <div className="calendar-card">
      <h2>Past Sessions</h2>
      <div className="session-list">
        {passedSessions.length > 0 ? (
          passedSessions.map((session, index) => (
            <div key={index} className="session-item">
              {session.date.toLocaleDateString('en-US', { weekday: 'short', month: 'short', year: 'numeric', day: 'numeric' })} - {session.reminder} - {session.attendanceStatus} 

            </div>
          ))
        ) : (
          <div className="no-sessions">No past sessions</div>
        )}
      </div>
    </div>
  );
};

export default AttendanceCard;